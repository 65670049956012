<template>
  <div class="select__container">
    <div
      v-click-outside="blurInput"
      :class="{
        'select--input': focusInput === true,
        'select--multiple': multiple,
        'select--loading': loading,
        'select--error': errors.length,
        'select--list': dropdownState === dropdownStates.BOTTOM,
        'select--list-top': dropdownState === dropdownStates.TOP,
        'select--no-title': !title,
      }"
      class="select"
    >
      <span
        v-if="multiple && selectedOptions && selectedOptions.length"
        v-show="!loading"
        class="select__selected"
      >
        <a class="select__selected-item" href="#" @click.prevent="select(selectedOptions[0])">
          <span>{{ cutString(getName(selectedOptions[0]), 12) }}</span>
          <close />
        </a>
        <a
          v-if="selectedOptions.length > 1"
          class="select__selected-item"
          href="#"
          @click.prevent="$refs.input.focus()"
        >
          <span>...</span>
        </a>
      </span>
      <div v-show="!loading" class="select__field-container">
        <input
          ref="input"
          v-model="search"
          :placeholder="title"
          class="select__field"
          @focus="handleInputFocus"
          :readonly="!searchable"
        />
      </div>
      <button
        v-if="!multiple && search.length && clearable"
        v-show="!loading"
        class="select__clear"
        type="button"
        @click="clean"
      >
        x
      </button>
<!--      <button v-show="!loading" class="select__action" tabindex="-1" type="button" @click="toggle">-->
<!--        +-->
<!--      </button>-->
      <SelectListComponent
        v-if="dropdownState !== dropdownStates.CLOSED"
        v-show="!loading"
        ref="list"
        :clearable="clearable"
        :labelName="labelName"
        :multiple="multiple"
        :options="filteredOptions"
        :selected="selectedOptions ? selectedOptions : value"
        @clean="clean"
        @select="select"
      />
    </div>
    <ul v-if="errors && errors.length" class="select__errors">
      <li v-for="(e, i) in errors" :key="i">{{ e }}</li>
    </ul>
    <ul v-if="messages && messages.length" class="select__errors select__errors--messages">
      <li v-for="(m, i) in messages" :key="i">{{ m }}</li>
    </ul>
  </div>
</template>

<script>
import SelectListComponent from './components/SelectListComponent.vue'
import Close from '@/components/svg/close'

export default {
  name: 'SelectComponent',
  props: {
    title: {
      type: String,
      default () {
        return null
      }
    },
    labelName: {
      type: String,
      default () {
        return 'name'
      }
    },
    clearable: {
      type: Boolean,
      default () {
        return true
      }
    },
    searchable: {
      type: Boolean,
      default () {
        return true
      }
    },
    options: Array,
    loading: Boolean,
    multiple: Boolean,
    dark: Boolean,
    errors: {
      type: Array,
      default () {
        return []
      }
    },
    messages: {
      type: Array,
      default () {
        return []
      }
    },
    value: [Number, String, Array, Object]
  },
  data () {
    return {
      selected: [],
      search: '',
      listTop: false,
      scrollTimeout: undefined,
      sbOptions: {
        minScrollbarLength: 20
      },
      dropdownStates: {
        CLOSED: 0,
        TOP: 1,
        BOTTOM: 2
      },
      dropdownState: null,
      focusInput: null
    }
  },
  computed: {
    selectedOptions () {
      if (this.value && this.value instanceof Array) {
        // Сделано так чтобы сохранять последовательность выбора
        return this.value
          .map((v) => {
            const option = this.options.find((o) => JSON.stringify(o) === JSON.stringify(v))
            if (option) {
              return option
            }
            return false
          })
          .filter((v) => v)
          .reverse()
        // return this.options.filter((o) => this.value.includes(o.id));
      }
      return false
    },
    filteredOptions () {
      return this.options.filter((o) => this.getName(o).includes(this.search))
    }
  },
  watch: {
    value (v) {
      if (!this.multiple) {
        this.select(v)
      }
    }
  },
  created () {
    // Изначальное значение выпадаюшего списка
    this.dropdownState = this.dropdownStates.CLOSED
  },
  mounted () {
    this.showInSearch()
    // Положение выпадающего списка реагирует на скролл страницы
    window.addEventListener('scroll', () => {
      if (this.dropdownState !== this.dropdownStates.CLOSED) {
        this.openDrop()
      }
    })
  },
  beforeDestroy () {
    // Удаляю слушатель скролла страницы при уничтожении элемента
    window.removeEventListener('scroll', () => {})
  },
  methods: {
    showInSearch () {
      if (this.value !== null && !this.multiple) {
        const option = this.options.find((o) => JSON.stringify(o) === JSON.stringify(this.value))
        if (option) {
          this.search = this.getName(option) || ''
        }
      }
    },
    handleInputFocus () {
      this.focusInput = true
      this.search = ''
      this.openDrop()
    },
    blurInput () {
      this.showInSearch()
      if (this.multiple) {
        this.search = ''
        this.$refs.input.blur()
        this.focusInput = false
        this.closeDrop()
      } else {
        this.$refs.input.blur()
        if (!this.value) {
          this.search = ''
        }
        this.focusInput = false
        this.closeDrop()
      }
    },
    toggle () {
      // Переключение состояния Input
      if (this.focusInput !== false) {
        this.blurInput()
      } else {
        this.$refs.input.focus()
      }
    },
    openDrop () {
      if (this.$refs.input) {
        const height = 200 // Минимум пространства
        const bottom = this.$refs.input.getBoundingClientRect().bottom
        if (bottom + height > window.innerHeight) {
          this.dropdownState = this.dropdownStates.TOP
          return
        }
        this.dropdownState = this.dropdownStates.BOTTOM
      }
    },
    closeDrop () {
      this.dropdownState = this.dropdownStates.CLOSED
    },
    /**
     * Обработка события выбора в списке
     */
    select (e) {
      // Если несколько элементов
      if (this.multiple) {
        // Фокусируюсь обратно на поле поиска
        this.$refs.input.focus()
        // Открепляю от реактивного массива уже выбранных value
        let value = JSON.parse(JSON.stringify(this.value || [])).map((v) => JSON.stringify(v))
        // Если уже выбран - удаляю
        // иначе заношу в конец массива выбранных
        if (value.includes(JSON.stringify(e))) {
          value.splice(value.indexOf(JSON.stringify(e)), 1)
        } else {
          value.push(JSON.stringify(e))
        }
        this.$emit(
          'input',
          // Не превращенные в string JSON объекты превращаю обратно
          // и делаю массив полностью из json
          value.map((v) => {
            if (typeof v === 'string') {
              return JSON.parse(v)
            }
            return v
          })
        )
        return
      }
      // Если один элемент
      // Вставляю в Input label выбранного объекта
      this.search = e ? this.getName(e) : ''
      // Сравниваю 2 stringify JSON строки
      // если не равна уже выбранной
      // если выбрано "развибирать нельзя"
      // повторяю поведение обычного select
      if (JSON.stringify(this.value) !== JSON.stringify(e)) {
        this.$emit('input', e)
      }
      // Закрываю выпадающий список
      this.blurInput()
    },
    /**
     * Получение label объекта
     * @param option
     * @returns {*}
     */
    getName (option) {
      if (this.labelName) {
        return option[this.labelName]
      }
      return option
    },
    cutString (string, size) {
      return string.substr(0, size).trim() + (string.length > size ? '..' : '')
    },
    clean () {
      if (this.multiple) {
        this.$emit('input', [])
        return
      }
      this.$emit('input', null)
      this.search = ''
    }
  },
  components: {
    Close,
    SelectListComponent
  }
}
</script>

<style lang="stylus">
@keyframes stripped {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 31px 62px;
  }
}

.select {
  box-sizing: border-box;
  position relative
  cursor text
  height 52px
  transition 0.2s
  display flex
  margin 0

  input {
    border none !important
  }

  &--error {
    border-color $red
  }

  &__errors {
    font-size: 0.750em;
    line-height: 15px;
    color: $red;
    display flex
    flex-direction column
    gap 5px

    &--messages {
      color: $dark;
    }
  }

  &__container {
    display flex
    flex-direction column
    align-self flex-start
    gap 5px
    width 100%
    border: 1px solid $c4E555A;
    background-image linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
    background-size 5px 5px, 5px 5px
    background-repeat no-repeat
    background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px);
  }

  &__field {
    border none
    box-sizing border-box
    background none !important
    width 100%
    height 100%
    padding 20px 12px 5px
    outline none
    font-weight: normal;
    font-size: 0.875em;
    appearance none
    resize vertical
    z-index 1

    &::placeholder {
      font-weight: normal;
      color $dark
    }
  }

  &--loading {
    background-color lighten($orange, 10%);
    background-size: 31px 31px;
    background-image: linear-gradient(-45deg, rgba(0, 0, 0, .1) 25%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .1) 0, rgba(0, 0, 0, .1) 75%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
    animation: stripped 2s linear 0s infinite normal none running;
  }

  &--no-title {
    & ^[0]__field {
      padding 15px 12px
    }
  }

  &--list {
    border-radius 2px 2px 0 0

    & ^[0]__action {
      z-index 11

      .icon {
        transform rotate(180deg)
      }
    }

    & ^[0]__clear {
      z-index 12
    }
  }

  &--list-top {
    border-radius 0 0 2px 2px

    & ^[0]__action {
      z-index 11

      .icon {
        transform rotate(180deg)
      }
    }

    & ^[0]__list {
      border-radius 2px 2px 0 0
      absolute top left
      bottom initial
      transform translateY(calc(-100% + 1px))
    }

    & ^[0]__clear {
      z-index 12
    }
  }

  &__action {
    background none
    outline none
    border none
    cursor pointer
    padding 10px
    absolute right top bottom
    margin auto
    transition .2s
    z-index 2
    display: flex;
    align-items: center;

    &:active
    &:focus {
      box-shadow: 0 0 0 2px $orange;
      border-radius: 2px;
    }

    .icon {
      transition .2s
      width 20px
      height 20px

      svg path {
        fill $dark
      }
    }
  }

  &__clear {
    @extend .select__action
    transform translate(-25px, -3px)
    z-index 3
  }

  &__field {
    padding 15px 12px
    z-index 1
    height 100%

    &-container {
      position relative
      width 100%
      display flex
      align-items center
      justify-content center
    }
  }

  &__selected {
    display flex
    gap 4px
    align-items center
    margin-left 12px
    flex-shrink: 0;
    +below(480px) {
      display none
    }

    .icon {
      width 12px
      height 12px
      display flex
      align-items center
      justify-content center

      svg {
        width 100%
        height 100%
      }
    }

    &-item {
      cursor pointer
      border-radius 2px
      padding 6px 12px
      font-size: 0.8750em;
      line-height: 16px;
      text-align: center;
      text-decoration none
      flex-shrink: 0;
      color $white
      background $orange

      &:hover {
        color $white

        svg path {
          fill $red
        }
      }

      &:active
      &:focus {
        box-shadow: 0 0 0 4px $orange;
      }

      svg {
        width 10px
        height 10px

        path {
          fill $white
        }
      }

      &:first-child {
        display flex
        gap 4px
        align-items center

        span {
          display flex
        }

        .icon {
          width 14px
          height 14px

          svg {
            width: 100%;
            height 100%
          }
        }
      }
    }
  }

  &--multiple {
    display flex
    align-items center
    //padding-right 40px
  }
}
</style>
