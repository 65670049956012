<template>
  <label
    class="check"
    :class="{
      'check--disabled': disabled,
      'check--error': error,
      'check--checked': checked,
      'check--indeterminate': indeterminate,
    }"
  >
    <span class="check__state">
      <template v-if="checked">
        <check-icon />
      </template>
    </span>
    <input
      v-show="false"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      :indeterminate="indeterminate"
      @change="$emit('change', $event.target.checked)"
    />
    <slot />
  </label>
</template>

<script>
import CheckIcon from '@/components/svg/CheckIcon'

export default {
  name: 'CheckboxComponent',
  components: { CheckIcon },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    error: Boolean,
    checked: Boolean,
    disabled: Boolean,
    indeterminate: Boolean
  }
}
</script>

<style lang="stylus">
.check {
  display flex
  gap 8px
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  user-select none
  font-size: 0.875em;
  line-height: 20px;
  transition 0.2s

  &:hover {
    color $orange !important
  }

  input {
    absolute left top
    visibility hidden
  }

  &--error {
    color $red !important

    & .check__state {
      border-color $red !important
    }
  }

  &--disabled {
    color $darkgray

    & .check__state {
      background: $darkgray;
      border: 1px solid $darkgray;
    }

    &.check--indeterminate
    &.check--checked {
      & .check__state {
        background: $black;
        border: 1px solid $black;
      }

      svg path {
        stroke $white
      }
    }
  }

  &--indeterminate:not(&.check--disabled)
  &--checked:not(&.check--disabled) {
    color $black

    & .check__state {
      background $orange
      border-color $orange

      svg path {
        stroke $white
      }
    }
  }

  &__state {
    width 20px
    height 20px
    background: $white
    border: 1px solid $dark;
    box-sizing: border-box;
    border-radius: 4px;
    display flex
    align-items center
    justify-content center
    flex-shrink 0
    transition 0.2s

    .icon {
      width 16px
      height 16px
      display flex
      align-items center
      justify-content center

      svg path {
        transition 0.2s
        fill transparent
      }
    }
  }
}
</style>
